import { autorun } from 'mobx';

import { createBiLogger } from '../../viewer';
import { ElementId } from './constants';
import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $props, $w }) => {
  const biLogger = createBiLogger(flowAPI.bi);

  return {
    async pageReady() {
      const userStates = $w(`#${ElementId.UserStates}`);

      autorun(() => userStates.changeState($props.isLoggedIn ? ElementId.LoggedInState : ElementId.LoggedOutState));

      $bind(`#${ElementId.LoginButton}`, {
        onClick: () => $widget.fireEvent('login', {}),
      });

      $bind(`#${ElementId.TitleText}`, {
        text: () => $props.title,
        collapsed: () => !$props.title,
      });

      $bind(`#${ElementId.DescriptionText}`, {
        text: () => $props.description,
        collapsed: () => !$props.description,
      });

      $bind(`#${ElementId.ExplanationsText}`, {
        text: () => $props.explanations,
        collapsed: () => !$props.explanations,
      });

      $bind(`#${ElementId.CopyLink}`, {
        enabled: () => !$props.isLinkLoading,
        text: () => $props.referralLink,
        onCopy() {
          biLogger.referringCustomerAction('Copy link');
        },
        onInputClick() {
          biLogger.referringCustomerAction('Link Text Input Click');
        },
      });

      $bind(`#${ElementId.SocialBar}`, {
        pageToShare: () => `https://${$props.referralLink}`,
      } as any);
    },
    exports: {},
  };
});
