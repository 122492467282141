import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getLocale } from './getLocale';

const DEFAULT_CURRENCY = 'USD';

export function createCurrencyFormatter(flowAPI: PlatformControllerFlowAPI) {
  const { controllerConfig, getCurrencyFormatter } = flowAPI;
  const { wixCodeApi } = controllerConfig;
  const currency = wixCodeApi.site.currency ?? DEFAULT_CURRENCY;
  const locale = getLocale(flowAPI);

  const formatCurrencyWithoutCents = getCurrencyFormatter({
    language: locale,
    minimumFractionDigits: 0,
  });

  const formatCurrencyWithCents = getCurrencyFormatter({
    language: locale,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (value: number) => {
    const hasFraction = value % 1 !== 0;
    return hasFraction ? formatCurrencyWithCents({ value, currency }) : formatCurrencyWithoutCents({ value, currency });
  };
}
