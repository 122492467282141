import { Type as RewardType } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { autorun, computed, runInAction, when } from 'mobx';

import { createBiLogger, createNumberFormatter, initCommonState, promptLogin } from '../../viewer';
import { ElementId, ViewStateId } from './constants';
import model from './model';
import {
  defaultState,
  getReferralCode,
  getReferralLink,
  getReferralPageUrl,
  hasCompletedReferralAction,
  hasUnusedCouponRewards,
} from './viewer';

const CONDITION_SUFFIX = '*';

export default model.createController(({ initState, $bind, $w, flowAPI }) => {
  const { state } = initState(defaultState);
  const { errorMonitor, bi } = flowAPI;
  const { t } = flowAPI.translations;
  const { isViewer } = flowAPI.environment;
  const biLogger = createBiLogger(bi);
  const formatNumber = createNumberFormatter(flowAPI);
  let pageReadyInitialized = false; // In Editor toggling elements within Elements panel calls pageReady again

  return {
    async pageReady() {
      if (pageReadyInitialized) {
        return;
      }

      pageReadyInitialized = true;

      const programStates = $w(`#${ElementId.ProgramStates}`);
      const cookieManager = $w(`#${ElementId.CookieManager}`);

      await initCommonState(state, flowAPI);

      const { rewardDescriptions, referralProgram } = state;
      const friendRewardType = computed<RewardType>(() => state.referralProgram?.referredFriendReward?.type!);

      state.friendRewardTitle = rewardDescriptions?.getFriendRewardTitle(referralProgram!) ?? '';
      state.customerRewardTitle = rewardDescriptions?.getCustomerRewardTitle(referralProgram!) ?? '';

      try {
        const [friendRewardCondition, customerRewardCondition] = await Promise.all([
          rewardDescriptions?.getFriendRewardCondition(referralProgram!) ?? '',
          rewardDescriptions?.getCustomerRewardCondition(referralProgram!) ?? '',
        ]);

        state.friendRewardCondition = friendRewardCondition;
        state.customerRewardCondition = customerRewardCondition;
      } catch (error) {
        errorMonitor?.captureException(error as Error);
        console.error(error);
      }

      state.referralPageUrl = await getReferralPageUrl(flowAPI);

      const useConditionSuffix = computed<boolean>(() => {
        if (!state.friendRewardTitle || !state.customerRewardTitle) {
          return false;
        }

        if (state.friendRewardCondition && state.customerRewardCondition) {
          return state.friendRewardCondition !== state.customerRewardCondition;
        }

        return true;
      });

      autorun(() =>
        programStates.changeState(state.isProgramAvailable ? ElementId.AvailableState : ElementId.NotAvailableState),
      );

      $bind(`#${ElementId.CookieManager}`, {
        onChange(event) {
          state.cookieReferralCode = event.target.value;
        },
      });

      when(
        () => state.isLoggedIn,
        async () => {
          cookieManager.value = '';

          const [hasCompletedReferralActionResponse, hasUnusedCouponRewardsResponse] = await Promise.all([
            hasCompletedReferralAction(flowAPI),
            friendRewardType.get() === RewardType.COUPON ? hasUnusedCouponRewards(flowAPI) : false,
          ]);

          state.hasCompletedReferralAction = hasCompletedReferralActionResponse;
          state.hasUnusedCouponRewards = hasUnusedCouponRewardsResponse;
        },
      );

      $bind(`#${ElementId.StatusText}`, {
        text: () => state.statusErrorMessage || t('status.not-available'),
      });

      const descriptionText = computed<string>(() => {
        const { friendRewardTitle, friendRewardCondition, customerRewardTitle, customerRewardCondition } = state;
        const conditionSuffix = useConditionSuffix.get() ? CONDITION_SUFFIX : '';
        const rewardParts: { title: string; conditionCount: number }[] = [];
        let currentConditionCount = 0;

        if (friendRewardTitle) {
          rewardParts.push({
            title: friendRewardTitle,
            conditionCount: friendRewardCondition ? ++currentConditionCount : 0,
          });
        }

        if (customerRewardTitle) {
          rewardParts.push({
            title: customerRewardTitle,
            conditionCount: customerRewardCondition
              ? customerRewardCondition === friendRewardCondition
                ? currentConditionCount
                : ++currentConditionCount
              : 0,
          });
        }

        const rewardTitles = rewardParts.map(({ title, conditionCount }) => {
          return `${title} ${conditionSuffix.repeat(conditionCount)}`.trim();
        });

        if (rewardTitles.length >= 2) {
          return rewardTitles.map((title, index) => `${index + 1}. ${title}`).join('\n');
        } else if (rewardTitles.length === 1) {
          return rewardTitles[0];
        } else {
          return '';
        }
      });

      const explanationsText = computed<string>(() => {
        const { friendRewardCondition, customerRewardCondition } = state;
        const conditionParts: string[] = [];
        const conditionSuffix = useConditionSuffix.get() ? CONDITION_SUFFIX : '';

        if (friendRewardCondition) {
          conditionParts.push(friendRewardCondition);
        }

        if (customerRewardCondition && !conditionParts.includes(customerRewardCondition)) {
          conditionParts.push(customerRewardCondition);
        }

        return conditionParts
          .map((condition, index) => `${conditionSuffix.repeat(index + 1)} ${condition}`)
          .join('\n')
          .trim();
      });

      when(
        () => state.isLoggedIn && !state.referralCode,
        async () => {
          state.referralCode = await getReferralCode(flowAPI);
          state.isLinkLoading = false;
        },
      );

      const referralLink = computed<string>(() => getReferralLink(state));

      const shouldShowNotification = computed<boolean>(() => {
        if (state.forceShowNotification) {
          return true;
        } else if (!isViewer) {
          return false;
        } else if (friendRewardType.get() === RewardType.NOTHING) {
          return false;
        } else if (state.referralCode === state.cookieReferralCode) {
          return false; // Cookie referral code matches customer code (user has visited its own referral link).
        } else if (state.isLoggedIn) {
          if (friendRewardType.get() === RewardType.LOYALTY_POINTS && !state.userLoyaltyPoints) {
            return false;
          } else if (friendRewardType.get() === RewardType.COUPON && !state.hasUnusedCouponRewards) {
            return false;
          }

          return !state.hasCompletedReferralAction;
        } else {
          return !!state.cookieReferralCode;
        }
      });

      $bind(`#${ElementId.NotificationBanner}`, {
        collapsed: () => !shouldShowNotification.get(),
        title() {
          if (friendRewardType.get() === RewardType.LOYALTY_POINTS) {
            const count =
              state.userLoyaltyPoints || state.referralProgram?.referredFriendReward?.loyaltyPointsOptions?.amount!;
            const customPointsName = state.loyaltyProgram?.pointDefinition?.customName;

            if (customPointsName) {
              return t('refer-friend-page.notification.title-points.custom', {
                pointsName: customPointsName,
                formattedCount: formatNumber(count),
              });
            } else {
              return t('refer-friend-page.notification.title-points', {
                count,
                formattedCount: formatNumber(count),
              });
            }
          } else {
            return rewardDescriptions?.getFriendTitle(referralProgram!) ?? '';
          }
        },
        description() {
          if (friendRewardType.get() === RewardType.LOYALTY_POINTS) {
            return t('refer-friend-page.notification.description-points');
          } else {
            return t('refer-friend-page.notification.description-coupon');
          }
        },
      });

      $bind(`#${ElementId.Content}`, {
        isLoggedIn: () => state.isLoggedIn,
        title: () => rewardDescriptions?.getCustomerTitle(referralProgram!) ?? '',
        description: () => descriptionText.get(),
        explanations: () => explanationsText.get(),
        isLinkLoading: () => state.isLinkLoading,
        referralLink: () => referralLink.get(),
        async onLogin() {
          biLogger.referringCustomerAction('login to refer');
          state.isLoggedIn = await promptLogin(flowAPI, 'login');
        },
      });
    },
    updateWidgetViewState(viewStateId: ViewStateId) {
      runInAction(() => {
        state.isLoggedIn = viewStateId !== ViewStateId.LoggedOut;
        state.forceShowNotification = viewStateId === ViewStateId.Notification;
      });
    },
    exports: {},
  };
});
