export enum ElementId {
  TitleText = 'titleText',
  SubtitleText = 'subtitleText',
  ExplanationsText = 'explanationsText',
  RewardStates = 'rewardStates',
  LoadingState = 'loadingState',
  CouponRewardState = 'couponRewardState',
  GetRewardState = 'getRewardState',
  GetRewardButton = 'getRewardButton',
  Loader = 'loader',
  Divider = 'divider',
  ApplyCodeText = 'applyCodeText',
  MyRewardsText = 'myRewardsText',
  CopyCode = 'copyCode',
}
