import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'cookieManager',
  props: {
    value: {
      type: WidgetPropertyType.STRING,
      title: 'Referral cookie value',
      description: 'Get or set referral cookie value.',
    },
  },
  methods: {},
  events: {
    change: {
      description: 'Event fired when referral cookie value changes.',
    },
  },
});
