export enum ElementId {
  CopyLink = 'copyLink',
  CopyLinkText = 'copyLinkText',
  DescriptionText = 'descriptionText',
  Divider = 'divider',
  ExplanationsText = 'explanationsText',
  LoggedInState = 'loggedInState',
  LoggedOutState = 'loggedOutState',
  LoginButton = 'loginButton',
  ShareText = 'shareText',
  SocialBar = 'socialBar',
  SubtitleText = 'subtitleText',
  TitleText = 'titleText',
  UserStates = 'userStates',
}
