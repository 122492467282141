import { queryReferralRewards } from '@wix/ambassador-loyalty-referral-v1-referral-reward/http';
import {
  ReferralReward,
  RewardTypeType as RewardType,
} from '@wix/ambassador-loyalty-referral-v1-referral-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  rewardType: RewardType;
}

export const getReferredFriendReward = async ({ flowAPI, rewardType }: Params): Promise<ReferralReward | undefined> => {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(
    () =>
      httpClient.request(
        queryReferralRewards({
          contactId: 'me',
          query: {},
        }),
      ),
    { errorCodesMap: {} },
  );

  const reward = response.data.referralRewards?.find(
    (referralReward) => !!referralReward.rewardedReferredFriendId && referralReward.rewardType === rewardType,
  );

  return reward;
};
