import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

const DEFAULT_LOCALE = 'en-US';

export function getLocale(flowAPI: PlatformControllerFlowAPI) {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const isMultilingualEnabled = wixCodeApi.window.multilingual?.isEnabled ?? false;

  if (isMultilingualEnabled) {
    const currentLanguage = wixCodeApi.window.multilingual.currentLanguage;

    const multilingualLocale = wixCodeApi.window.multilingual.siteLanguages.find(
      (language) => language.languageCode === currentLanguage,
    )?.locale;

    if (multilingualLocale) {
      return multilingualLocale;
    }
  }

  return wixCodeApi.site.regionalSettings ?? DEFAULT_LOCALE;
}
