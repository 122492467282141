import { autorun } from 'mobx';

import { ElementId } from './constants';
import model from './model';
import { defaultState } from './viewer';

const COPIED_DISPLAY_TIME = 3000;
const COPIED_ICON_SVG = `
<svg data-bbox="44 59 112 82.683" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <g><path d="M82.28 141.683 44 101.817l5.656-5.892L82.28 129.9 150.344 59 156 64.892l-73.72 76.791Z" fill-rule="evenodd"></path></g>
</svg>`;

export default model.createController(({ initState, $bind, flowAPI, $props, $w, $widget }) => {
  const { state } = initState(defaultState);
  const { wixCodeApi } = flowAPI.controllerConfig;

  return {
    async pageReady() {
      const copyButton = $w(`#${ElementId.CopyButton}`);
      const originalCopyButtonLabel = copyButton.label;

      autorun(() => {
        if (!$props.enabled) {
          copyButton.disable();
        } else {
          copyButton.enable();
        }
      });

      $bind(`#${ElementId.TextInput}`, {
        value: () => $props.text,
        onClick() {
          $widget.fireEvent('inputClick', {});
        },
      });

      $bind(`#${ElementId.CopyButton}`, {
        icon: () => (state.isTextCopied ? COPIED_ICON_SVG : null),
        label: () => (state.isTextCopied ? '' : originalCopyButtonLabel),
        async onClick() {
          await wixCodeApi.window.copyToClipboard($props.text);

          if (!state.isTextCopied) {
            state.isTextCopied = true;
            setTimeout(() => (state.isTextCopied = false), COPIED_DISPLAY_TIME);
          }

          $widget.fireEvent('copy', {});
        },
      } as any);
    },
    exports: {},
  };
});
