import { State } from './state';

const LINK_LOADING_PLACEHOLDER = '...';
const UTM_SOURCE = 'referral_program';

export function getReferralLink(state: State): string {
  if (state.isLinkLoading) {
    return LINK_LOADING_PLACEHOLDER;
  }

  const url = new URL([state.referralPageUrl, state.referralCode].join('/'));
  url.searchParams.set('utm_source', UTM_SOURCE);

  return url.toString().replace(/(^\w+:|^)\/\//, '');
}
