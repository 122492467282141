import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'referFriendPageContent',
  props: {
    isLoggedIn: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: false,
    },
    title: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    description: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    explanations: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    referralLink: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    isLinkLoading: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
    },
  },
  methods: {},
  events: {
    login: {},
  },
});
