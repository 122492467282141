import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'notificationBanner',
  props: {
    title: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    description: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
  },
  methods: {},
  events: {},
});
