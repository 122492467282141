import { createReferredFriend } from '@wix/ambassador-loyalty-referral-v1-referred-friend/http';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface CreateReferredFriendError {
  details?: {
    applicationError?: {
      code?: string;
    };
  };
}

const isMemberNotEligibleError = (error: CreateReferredFriendError) =>
  error.details?.applicationError?.code === 'MEMBER_NOT_ELIGIBLE';

export const isReferredFriendEligible = async (flowAPI: PlatformControllerFlowAPI): Promise<boolean> => {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(createReferredFriend({})), {
      errorCodesMap: {
        applicationError: {
          MEMBER_NOT_ELIGIBLE: false,
        },
      },
    });

    return !!response.data.referredFriend;
  } catch (error: any) {
    if (isMemberNotEligibleError(error.response?.data as CreateReferredFriendError)) {
      return false;
    }

    return true;
  }
};
