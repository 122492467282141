import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { getDemoUserLoyaltyPoints } from './getDemoUserLoyaltyPoints';

export async function getUserLoyaltyPoints(flowAPI: PlatformControllerFlowAPI): Promise<number | undefined> {
  const { httpClient } = flowAPI;
  const { isViewer } = flowAPI.environment;
  const { withErrorHandler } = flowAPI.errorHandler;

  if (!isViewer) {
    return getDemoUserLoyaltyPoints();
  }

  try {
    const response = await withErrorHandler(() => httpClient.request(getCurrentMemberAccount({})), {
      errorCodesMap: {},
    });

    return response.data.account?.points?.balance;
  } catch (error) {
    // When user has no points loyalty account doesn't exist.
    if (error instanceof Error && error.message.includes('404')) {
      return;
    }

    throw error;
  }
}
