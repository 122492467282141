import { Type as RewardType } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { autorun, computed, runInAction, when } from 'mobx';

import {
  createBiLogger,
  createNumberFormatter,
  getMyRewardsPageUrl,
  initCommonState,
  isMyRewardsPageInstalled,
  navigateToMyRewardsPage,
  promptLogin,
} from '../../viewer';
import { ElementId, ViewStateId } from './constants';
import model from './model';
import {
  defaultState,
  getCouponRewardCode,
  isReferredFriendEligible,
  waitForCouponReward,
  waitForLoyaltyPointsReward,
} from './viewer';

export default model.createController(({ initState, $bind, $w, flowAPI }) => {
  const { state } = initState(defaultState);
  const { errorMonitor, bi } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isViewer } = flowAPI.environment;
  const { t } = flowAPI.translations;
  const biLogger = createBiLogger(bi);
  const formatNumber = createNumberFormatter(flowAPI);
  let pageReadyInitialized = false; // In Editor toggling elements within Elements panel calls pageReady again

  const setReferralCookieFromUrlParam = () => {
    const { isSSR } = flowAPI.environment;
    if (isSSR) {
      return;
    }

    const { location } = wixCodeApi;
    const referralCode = location.path[1];
    if (!referralCode) {
      return;
    }

    const cookieManager = $w(`#${ElementId.CookieManager}`);
    cookieManager.value = referralCode;
  };

  return {
    async pageReady() {
      if (pageReadyInitialized) {
        return;
      }

      pageReadyInitialized = true;

      setReferralCookieFromUrlParam();
      await initCommonState(state, flowAPI);

      const { rewardDescriptions, referralProgram } = state;

      try {
        state.explanationsText = (await rewardDescriptions?.getFriendRewardCondition(referralProgram!)) ?? '';
      } catch (error) {
        errorMonitor?.captureException(error as Error);
        console.error(error);
      }

      const programStates = $w(`#${ElementId.ProgramStates}`);
      const hasMyRewardsPage = await isMyRewardsPageInstalled(flowAPI);
      const myRewardsPageUrl = hasMyRewardsPage ? await getMyRewardsPageUrl(flowAPI) : '';
      const friendRewardType = computed<RewardType>(() => state.referralProgram?.referredFriendReward?.type!);

      autorun(() =>
        programStates.changeState(state.isProgramAvailable ? ElementId.AvailableState : ElementId.NotAvailableState),
      );

      $bind(`#${ElementId.CookieManager}`, {
        onChange(event) {
          state.cookieReferralCode = event.target.value;
        },
      });

      $bind(`#${ElementId.StatusText}`, {
        text: () => state.statusErrorMessage || t('status.not-available'),
      });

      const hasUsedReward = computed<boolean>(
        () =>
          state.isLoggedIn &&
          !state.isRewardLoading &&
          ((friendRewardType.get() === RewardType.LOYALTY_POINTS && !state.userLoyaltyPoints) || state.isRewardUsed),
      );

      const title = computed<string>(() => {
        if (state.isTimeoutError) {
          return t('referral-page.timeout-error.title');
        }
        if (state.isNotEligibleError) {
          return t('referral-page.not-eligible.title');
        }
        if (hasUsedReward.get()) {
          return t('referral-page.title.points.empty');
        }
        if (friendRewardType.get() === RewardType.LOYALTY_POINTS && state.isLoggedIn && !state.isRewardLoading) {
          const count = state.userLoyaltyPoints;

          const customPointsName = state.loyaltyProgram?.pointDefinition?.customName;
          if (customPointsName) {
            return t('referral-page.title.points.custom', {
              formattedCount: formatNumber(count!),
              pointsName: customPointsName,
            });
          }

          return t('referral-page.title.points', { count, formattedCount: formatNumber(count!) });
        }
        return rewardDescriptions?.getFriendTitle(referralProgram!) ?? '';
      });

      const subtitle = computed<string>(() => {
        if (state.isTimeoutError) {
          return t('referral-page.timeout-error.description');
        }
        if (state.isNotEligibleError) {
          return t('referral-page.not-eligible.description');
        }
        if (friendRewardType.get() === RewardType.NOTHING) {
          return t('referral-page.subtitle.start-browsing');
        }
        if (friendRewardType.get() === RewardType.LOYALTY_POINTS) {
          return t('referral-page.subtitle.redeem-points');
        }
        return t('referral-page.subtitle.apply-reward');
      });

      const showGoToHomeButton = computed<boolean>(() => {
        if (state.isTimeoutError) {
          return true;
        }
        if (friendRewardType.get() === RewardType.NOTHING) {
          return true;
        }
        if (hasUsedReward.get()) {
          return true;
        }
        if (state.isLoggedIn && !hasMyRewardsPage) {
          return true;
        }

        return false;
      });

      const rewardButtonLabel = computed<string>(() => {
        if (showGoToHomeButton.get()) {
          return t('referral-page.go-to-home');
        }
        if (state.isNotEligibleError) {
          return t('referral-page.not-eligible.go-to-rewards');
        }
        return t('referral-page.get-reward');
      });

      const isUserInitiallyLoggedIn = state.isLoggedIn;

      when(
        () => state.isLoggedIn && isViewer,
        async () => {
          // Redirect to "My Rewards" page with successful loyalty points reward
          if (!isUserInitiallyLoggedIn && friendRewardType.get() === RewardType.LOYALTY_POINTS) {
            state.isRewardLoading = true;

            const isEligible = await isReferredFriendEligible(flowAPI);
            if (!isEligible) {
              state.isNotEligibleError = true;
              state.isRewardLoading = false;
              return;
            }

            const loyaltyPointsReward = await waitForLoyaltyPointsReward(flowAPI);
            if (!loyaltyPointsReward) {
              state.isTimeoutError = true;
              state.isRewardLoading = false;
            } else {
              await navigateToMyRewardsPage(flowAPI);
            }
          } else if (friendRewardType.get() === RewardType.COUPON) {
            state.isRewardLoading = true;
            const couponReward = await waitForCouponReward(flowAPI);

            runInAction(() => {
              state.isRewardUsed = couponReward.coupon?.status === 'APPLIED';
              state.couponReward = couponReward.coupon;
              state.isNotEligibleError = !couponReward.isEligible;
              state.isTimeoutError = !couponReward.coupon && couponReward.isEligible;
              state.isRewardLoading = false;
            });
          }
        },
      );

      $bind(`#${ElementId.Content}`, {
        isLoggedIn: () => state.isLoggedIn,
        isLoading: () => state.isRewardLoading,
        isError: () => state.isTimeoutError || state.isNotEligibleError,
        isRewardUsed: () => hasUsedReward.get(),
        title: () => title.get(),
        subtitle: () => (hasUsedReward.get() ? '' : subtitle.get()),
        explanations: () => (state.isTimeoutError || state.isNotEligibleError ? '' : state.explanationsText),
        couponCode: () => getCouponRewardCode({ flowAPI, state }),
        rewardType: () => friendRewardType.get(),
        myRewardsPageUrl: () => myRewardsPageUrl,
        rewardButtonLabel: () => rewardButtonLabel.get(),
        async onRewardButtonClick() {
          if (showGoToHomeButton.get()) {
            biLogger.referredFriendAction('Go to Home');
            wixCodeApi.location.to?.('/');
          } else {
            biLogger.referredFriendAction('Get Reward');
            if (!state.isLoggedIn) {
              state.isLoggedIn = await promptLogin(flowAPI, 'signup');
            } else {
              await navigateToMyRewardsPage(flowAPI);
            }
          }
        },
      });
    },
    updateWidgetViewState(viewStateId: ViewStateId) {
      state.isLoggedIn = viewStateId !== ViewStateId.LoggedOut;
    },
    exports: {},
  };
});
