export enum ElementId {
  CookieManager = 'cookieManager',
  ProgramStates = 'programStates',
  AvailableState = 'availableState',
  NotAvailableState = 'notAvailableState',
  StatusText = 'statusText',
  ContentContainer = 'contentContainer',
  Content = 'content',
  Image = 'image',
  ImageContainer = 'imageContainer',
}

export enum ViewStateId {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
}

export enum FedOpsInteraction {
  LoadReward = 'load-reward',
}

export const REFERRAL_PAGE_ID = 'wix-referrals-referral';
export const REFERRAL_PAGE_URI = 'referral';
