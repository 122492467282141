import { Type as RewardType } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { IUser, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { runInAction, when } from 'mobx';

import { getDecodedInstance } from './getDecodedInstance';
import { getUserLoyaltyPoints } from './getUserLoyaltyPoints';
import { initRewardDescriptions } from './initRewardDescriptions';
import { isProgramAvailable } from './isProgramAvailable';
import { loadRemoteStateData } from './loadRemoteStateData';
import { CommonState } from './state';

export async function initCommonState(state: CommonState, flowAPI: PlatformControllerFlowAPI): Promise<void> {
  const { errorMonitor } = flowAPI;
  const { isViewer } = flowAPI.environment;
  const { getResolvedError } = flowAPI.errorHandler;
  const { wixCodeApi } = flowAPI.controllerConfig;

  try {
    if (isViewer) {
      state.isLoggedIn = wixCodeApi.user.currentUser.loggedIn;

      wixCodeApi.user.onLogin((user: IUser) => {
        // This setTimeout is used to fix weird issue with WebKit (Safari/iOS) where `state.isLoggedIn` was
        // updated too soon (leading to backend not able to resolve contact with "Contact ID not found for
        // visitorId" error).
        setTimeout(() => {
          state.isLoggedIn = user.loggedIn;
        }, 0);
      });
    } else {
      state.isLoggedIn = true; // Default view state is "logged in" in Editor/Preview
    }

    const isSiteTemplate = getDecodedInstance(flowAPI).siteIsTemplate ?? false;

    await loadRemoteStateData({ flowAPI, state, isSiteTemplate });

    const { referralProgram, loyaltyProgram } = state;

    if (!isProgramAvailable({ isSiteTemplate, isViewer, referralProgram })) {
      state.isProgramAvailable = false;
      return;
    }

    const shouldLoadLoyaltyPoints = referralProgram?.referredFriendReward?.type === RewardType.LOYALTY_POINTS;

    if (!state.isLoggedIn && shouldLoadLoyaltyPoints) {
      when(
        () => state.isLoggedIn,
        async () => {
          state.userLoyaltyPoints = await getUserLoyaltyPoints(flowAPI);
        },
      );
    }

    const customPointsName = loyaltyProgram?.pointDefinition?.customName ?? undefined;

    state.rewardDescriptions = await initRewardDescriptions({ flowAPI, customPointsName });
  } catch (error) {
    errorMonitor?.captureException(error as Error);
    console.error(error);

    runInAction(() => {
      state.isProgramAvailable = false;
      state.statusErrorMessage = getResolvedError(error).message;
    });
  }
}
