import model from './model';

export default model.createController(({ $widget, $w, flowAPI }) => {
  const { isViewer } = flowAPI.environment;
  const cmCustomElement = $w('#cmCustomElement');

  const updateValue = (value: string) => {
    $widget.props.value = value;
    $widget.fireEvent('change', {});
  };

  if (isViewer) {
    $widget.onPropsChanged((oldProps, newProps) => {
      if (oldProps.value !== newProps.value) {
        cmCustomElement.setAttribute('code', newProps.value);
        updateValue(newProps.value);
      }
    });
  }

  return {
    async pageReady() {
      if (!isViewer) {
        return;
      }

      let readyPollAttempt = 1;

      // Read initial cookie value. This is a bit hacky - but to read and set cookies in Blocks
      // we are using custom element. The only way to pass something back to a worker context from
      // custom element is with `on()` event function. There is also a bug where you cannot dispatch
      // events too soon - hence the polling. See custom element implementation for more details.
      const readyIntervalHandle = setInterval(() => {
        cmCustomElement.setAttribute('ready', readyPollAttempt);
        readyPollAttempt++;
      }, 50);

      cmCustomElement.on('readCode', (event: CustomEvent<string>) => {
        updateValue(event.detail);
        clearInterval(readyIntervalHandle);
      });
    },
    exports: {},
  };
});
