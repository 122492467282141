import { getReferredFriendByContactId } from '@wix/ambassador-loyalty-referral-v1-referred-friend/http';
import { Status } from '@wix/ambassador-loyalty-referral-v1-referred-friend/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function hasCompletedReferralAction(flowAPI: PlatformControllerFlowAPI): Promise<boolean> {
  const { httpClient } = flowAPI;
  const { isViewer } = flowAPI.environment;
  const { withErrorHandler } = flowAPI.errorHandler;

  if (!isViewer) {
    return false;
  }

  try {
    const response = await withErrorHandler(
      () => httpClient.request(getReferredFriendByContactId({ contactId: 'me' })),
      { errorCodesMap: {} },
    );

    return response.data.referredFriend?.status === Status.ACTIONS_COMPLETED;
  } catch (error) {
    if (error instanceof Error && error.message.includes('404')) {
      return true;
    }

    throw error;
  }
}
