import { createBlocksModel, WidgetPropertyType } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'copyText',
  props: {
    text: {
      type: WidgetPropertyType.STRING,
      defaultValue: '',
    },
    enabled: {
      type: WidgetPropertyType.BOOLEAN,
      defaultValue: true,
    },
  },
  methods: {},
  events: {
    copy: {},
    inputClick: {},
  },
});
