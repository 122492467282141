import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { State } from './state';

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  state: State;
}

const EDITOR_DEMO_CODE = 'COUPONCODE';

export function getCouponRewardCode({ flowAPI, state }: Params): string {
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return EDITOR_DEMO_CODE;
  }

  return state.couponReward?.code ?? '';
}
