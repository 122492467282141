import { CommonState, defaultCommonState } from '../../../viewer';

export interface State extends CommonState {
  friendRewardTitle: string;
  friendRewardCondition: string;
  customerRewardTitle: string;
  customerRewardCondition: string;
  isLinkLoading: boolean;
  referralCode: string;
  referralPageUrl: string;
  hasCompletedReferralAction: boolean;
  hasUnusedCouponRewards: boolean;
  forceShowNotification: boolean; // For Editor preview state
}

export const defaultState: State = {
  ...defaultCommonState,
  friendRewardTitle: '',
  friendRewardCondition: '',
  customerRewardTitle: '',
  customerRewardCondition: '',
  isLinkLoading: true,
  referralCode: '',
  referralPageUrl: '',
  hasCompletedReferralAction: true,
  hasUnusedCouponRewards: false,
  forceShowNotification: false,
};
