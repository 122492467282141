import { ReferralProgram } from '@wix/ambassador-loyalty-referral-v1-program/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { RewardDescriptions } from '@wix/referrals-reward-descriptions';

// Common state used between both page widgets
export interface CommonState {
  statusErrorMessage: string;
  isProgramAvailable: boolean;
  isLoggedIn: boolean;
  cookieReferralCode?: string;
  referralProgram?: ReferralProgram;
  loyaltyProgram?: LoyaltyProgram;
  rewardDescriptions?: RewardDescriptions;
  userLoyaltyPoints?: number;
}

export const defaultCommonState: CommonState = {
  statusErrorMessage: '',
  isProgramAvailable: true,
  isLoggedIn: false,
  cookieReferralCode: undefined,
  referralProgram: undefined,
  loyaltyProgram: undefined,
  rewardDescriptions: undefined,
  userLoyaltyPoints: undefined,
};
