import {
  Action as RewardTrigger,
  DiscountType,
  ProgramStatus,
  ReferralProgram,
  Type as RewardType,
} from '@wix/ambassador-loyalty-referral-v1-program/types';

export function getDemoReferralProgram(): ReferralProgram {
  return {
    status: ProgramStatus.ACTIVE,
    premiumFeatures: {
      referralProgram: true,
    },
    successfulReferralActions: [RewardTrigger.STORE_ORDER_PLACED],
    referredFriendReward: {
      type: RewardType.COUPON,
      couponOptions: {
        discountType: DiscountType.FIXED_AMOUNT,
        limitedToOneItem: true,
        fixedAmountOptions: {
          amount: 10,
        },
        scope: {
          namespace: 'stores',
        },
      },
    },
    referringCustomerReward: {
      type: RewardType.COUPON,
      couponOptions: {
        discountType: DiscountType.PERCENTAGE,
        limitedToOneItem: false,
        percentageOptions: {
          percentage: 25,
        },
        scope: {
          namespace: 'stores',
        },
      },
    },
  };
}
