import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { REFERRAL_PAGE_ID, REFERRAL_PAGE_URI } from '../../referralPage/constants';

const EDITOR_DEMO_URL = `https://www.mysite.com/${REFERRAL_PAGE_URI}`;

export async function getReferralPageUrl(flowAPI: PlatformControllerFlowAPI): Promise<string> {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { appDefinitionId, isViewer } = flowAPI.environment;

  if (!isViewer) {
    return EDITOR_DEMO_URL;
  }

  const referralSection = await wixCodeApi.site.getSectionUrl({
    appDefinitionId,
    sectionId: REFERRAL_PAGE_ID,
  });

  return referralSection.url!;
}
