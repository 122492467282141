import { loadRewardDescriptions, RewardDescriptions } from '@wix/referrals-reward-descriptions';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createCurrencyFormatter } from './createCurrencyFormatter';
import { createNumberFormatter } from './createNumberFormatter';

interface Params {
  flowAPI: PlatformControllerFlowAPI;
  customPointsName?: string;
}

export async function initRewardDescriptions({ flowAPI, customPointsName }: Params): Promise<RewardDescriptions> {
  const { httpClient, translations } = flowAPI;

  const formatNumber = createNumberFormatter(flowAPI);
  const formatCurrency = createCurrencyFormatter(flowAPI);

  return loadRewardDescriptions({
    i18n: translations.i18n,
    httpClient,
    customPointsName,
    formatNumber,
    formatCurrency,
  });
}
