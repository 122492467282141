import { isMyRewardsPageInstalled, navigateToMyRewardsPage, promptLogin } from '../../viewer';
import { REFERRAL_PAGE_ID } from '../referralPage/constants';
import { ElementId } from './constants';
import model from './model';

export default model.createController(({ $bind, $props, flowAPI, $w }) => {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { appDefinitionId } = flowAPI.environment;

  return {
    async pageReady() {
      const { currentUser } = wixCodeApi.user;
      const hasMyRewardsPage = await isMyRewardsPageInstalled(flowAPI);

      const navigateToReferralPage = async () => {
        const referralSection = await wixCodeApi.site.getSectionUrl({
          appDefinitionId,
          sectionId: REFERRAL_PAGE_ID,
        });

        if (referralSection?.relativeUrl) {
          wixCodeApi.location.to?.(referralSection.relativeUrl);
        }
      };

      $bind(`#${ElementId.GetRewardButton}`, {
        async onClick() {
          if (hasMyRewardsPage) {
            if (currentUser.loggedIn) {
              await navigateToMyRewardsPage(flowAPI);
            } else {
              const isLoggedIn = await promptLogin(flowAPI, 'signup');
              if (isLoggedIn) {
                await navigateToMyRewardsPage(flowAPI);
              }
            }
          } else {
            await navigateToReferralPage();
          }
        },
      });

      $bind(`#${ElementId.NotificationTitleText}`, {
        collapsed: () => !$props.title,
        text: () => $props.title,
      });

      $bind(`#${ElementId.NotificationDescriptionText}`, {
        collapsed: () => !$props.description,
        text: () => $props.description,
      });
    },
    exports: {},
  };
});
